import request from '@/utils/request'


// 查询米币流水业务列表
export function listIntegralBill(query) {
  return request({
    url: '/platform/integral-bill/list',
    method: 'get',
    params: query
  })
}

// 查询米币流水业务分页
export function pageIntegralBill(query) {
  return request({
    url: '/platform/integral-bill/page',
    method: 'get',
    params: query
  })
}

// 查询米币流水业务详细
export function getIntegralBill(data) {
  return request({
    url: '/platform/integral-bill/detail',
    method: 'get',
    params: data
  })
}

// 新增米币流水业务
export function addIntegralBill(data) {
  return request({
    url: '/platform/integral-bill/add',
    method: 'post',
    data: data
  })
}

// 修改米币流水业务
export function updateIntegralBill(data) {
  return request({
    url: '/platform/integral-bill/edit',
    method: 'post',
    data: data
  })
}

// 删除米币流水业务
export function delIntegralBill(data) {
  return request({
    url: '/platform/integral-bill/delete',
    method: 'post',
    data: data
  })
}
